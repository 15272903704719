.root {
  color: #616A6F;
}

.wrapper {
  max-width: 70.25rem;
  margin-left: auto;
  margin-right: auto;
}
.wrapper:after {
  content: "";
  display: table;
  clear: both;
}

table, th, td {
  /*
  border: 1px solid black;
  border-collapse: collapse;
  */
}

.headerText{
  font-size: 3rem;
  letter-spacing: .0625em;
  font-weight: 700;
  text-transform: uppercase;
  color: #616A6F;
}
.contentBorder {
  border-bottom: 2px dotted #C1CACF;
  padding: 15px;
  min-height: 700px;
}

.footerNavigation {
  cursor: pointer;
}
.footerNavigation:hover {
  color: #215192;
  text-decoration: underline;
}

.immoPlanColor1{
  color: #215192;
}

.immoPlanColor2{
  color: #616A6F;
}

.immoPlanColor3{
  color: #000000;
}

.immoPlanLine1{
  border-bottom: 3px solid #215192;
}

.immoPlanLine2{
  border-bottom: 3px solid #616A6F;
}