.label{
  font-size: 16px;
  color: #000000;
}
.header{
  color: #215192;
  text-align: center;
  font-weight: 700;
  font-size: clamp(6px, 1vw, 18px);
}

.ergCell{
  text-align: center;
  font-weight: 700;
  font-size: clamp(2px, 2.5vw, 24px);
}

