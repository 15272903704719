.root{
  background-color: #f5f5f5;
  color: rgba(185, 40, 0, 0.34);
}

.root:hover{
  color: rgba(185, 40, 0, 0.52);
}

.icon2{
  font-size: 6vw;
}

.label{
  font-size: clamp(2px, 2.5vw, 20px);
  color: #000000;
}

.erg{
  font-weight: 700;
  font-size: clamp(2px, 2.5vw, 40px);
  color: #000000;
}

.haus{
  width: clamp(50px, 35vw, 550px);
}
.ergHouse{
  width: clamp(50px, 35vw, 550px);
  position: absolute;
  font-weight: 700;
  font-size: 2vw;
  color: #FFFFFF;
}

.header2{
  font-weight: 700;
  font-size: clamp(2px, 2.5vw, 40px);
  color: #000000;
}