.root{
  background-color: rgba(216,248,213,0.42);
  color: rgba(41, 170, 26, 0.48);
}

.root:hover{
  color: rgba(41, 170, 26, 0.65);
}

.icon2{
  font-size: 6vw;
}

.label{
  font-size: clamp(2px, 2.5vw, 20px);
  color: #000000;
}

.erg{
  font-weight: 700;
  font-size: clamp(2px, 2.5vw, 40px);
  color: #000000;
}

.haus{
  width: clamp(50px, 35vw, 550px);
}
.ergHouse{
  width: clamp(50px, 35vw, 550px);
  position: absolute;
  font-weight: 700;
  font-size: 2vw;
  color: #FFFFFF;
}

.header2{
  font-weight: 700;
  font-size: clamp(2px, 2.5vw, 40px);
  color: #000000;
}