.label{
  font-size: 16px;
  color: #000000;
}

.erg{
  font-weight: 700;
  font-size: 24px;
  color: #000000;
}

